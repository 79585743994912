import React from "react";
import {
  Navbar as MTNavbar,
  Collapse,
  IconButton,
  Typography,
  Button,
  Switch,
} from "@material-tailwind/react";
import { XMarkIcon, Bars3Icon } from "@heroicons/react/24/solid";
import Link from "next/link";


interface NavItemProps {
  children: React.ReactNode;
  href?: string;
  pageName: string
}
function NavItem({ children, href, pageName }: NavItemProps) {
  return (
    <li key={pageName}>
      <Link href={pageName}>
      
      <Typography
        // as="a"
        // href={href || "#"}
        target={href ? "_blank" : "_self"}

        className="font-bold transform hover:scale-110 transition duration-300" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}      >

        {children}
      </Typography>
      </Link>
   
    </li>
  );
}

export function Navbar() {
  const [open, setOpen] = React.useState(false);
  const [isScrolling, setIsScrolling] = React.useState(false);

  const handleOpen = () => setOpen((cur) => !cur);

  React.useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setOpen(false)
    );
  }, []);

  React.useEffect(() => {
    function handleScroll() {
      if (window.scrollY > 0) {
        setIsScrolling(true);
      } else {
        setIsScrolling(false);
      }
    }

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);



  return (
 
      <MTNavbar
        fullWidth
        shadow={false}
        blurred={false}
        color={isScrolling ? "white" : "transparent"}
        className="fixed top-0 z-50 border-0 animate-fade-down duration-500" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}    >
        <div className="container mx-auto flex items-center justify-between">
          {/* <Typography variant="h6" color={isScrolling ? "text-teal-900" : "white"} > */}
          <div className="hidden gap-2 lg:flex">
            <img src="/image/logo_tp.png" width={120} height={100} color={isScrolling ? "blue-teal" : "white"} alt="Hope Image" />
          </div>
          {/* </Typography> */}

          <ul
            className={`ml-10 hidden items-center gap-6 lg:flex ${isScrolling ? "text-teal-900" : "text-white"
              }`}>
            <NavItem pageName= "/" >Home</NavItem>
            <NavItem pageName='/aboutus'>About Us</NavItem>
            <NavItem pageName = '/courses'>Our Courses</NavItem>
            <NavItem pageName = '/gallery'>Our Gallery</NavItem>
            <NavItem pageName = '/contactus'>Contact Us</NavItem>

          </ul>
          <div className="hidden gap-2 lg:flex">
          
            <IconButton
          onClick={() => window.location.href = "https://www.facebook.com/hopeinternationalcare"}
              variant="text"
              color={isScrolling ? "teal" : "white"}
              size="sm" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}          >
              <i className="fa-brands fa-facebook text-base" />
            </IconButton>
            <IconButton
                      onClick={() => window.location.href = "https://www.instagram.com/hopeinternational_caregiver/"}
              variant="text"
              color={isScrolling ? "teal" : "white"}
              size="sm" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}          >
              <i className="fa-brands fa-instagram text-base" />
            </IconButton>
            {/* <a href="https://www.material-tailwind.com/blocks" target="_blank">
            <Button color={isScrolling ? "teal" : "white"} size="sm">
              Blocks
            </Button>
          </a> */}
          </div>
          <IconButton
            variant="text"
            color={isScrolling ? "teal" : "white"}
            onClick={handleOpen}
            className="ml-auto inline-block lg:hidden" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}        >
            {open ? (
              <XMarkIcon strokeWidth={2} className="h-6 w-6" />
            ) : (
              <Bars3Icon strokeWidth={2} className="h-6 w-6" />
            )}
          </IconButton>
        </div>
        <Collapse open={open}>
          <div className="container mx-auto mt-4 rounded-lg bg-white px-6 py-5">
            <ul className="flex flex-col gap-4 text-teal-900">
          
            <NavItem pageName= "/" >Home</NavItem>
            <NavItem pageName='/aboutus'>About Us</NavItem>
            <NavItem pageName = '/courses'>Our Courses</NavItem>
            <NavItem pageName = '/gallery'> Our Gallery</NavItem>
        
            <NavItem pageName = '/contactus'>Contact Us</NavItem>
              {/* <NavItem href="https://www.material-tailwind.com/docs/react/installation">
              Docs
            </NavItem>
            <NavItem href="https://www.material-tailwind.com/blocks">
              Blocks
            </NavItem> */}
            </ul>
            <div className="mt-4 flex gap-2">
              <IconButton variant="text" color="teal" size="sm" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                <i className="fa-brands fa-twitter text-base" />
              </IconButton>
              <IconButton variant="text" color="teal" size="sm" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                <i className="fa-brands fa-facebook text-base" />
              </IconButton>
              <IconButton variant="text" color="teal" size="sm" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                <i className="fa-brands fa-instagram text-base" />
              </IconButton>
              {/* <a href="https://www.material-tailwind.com/blocks" target="_blank">
              <Button color="teal" size="sm" className="ml-auto">
                Blocks
              </Button>
            </a> */}
            </div>
          </div>
        </Collapse>
      </MTNavbar>

  );
}

export default Navbar;
