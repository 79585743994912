import React from "react";
import {
  Typography,
  Card,
  CardBody,
} from "@material-tailwind/react";

interface FeatureCardProps {
  icon: React.ElementType;
  title: string;
  children: React.ReactNode;
}
export function FeatureCard({ icon: Icon, title, children }: FeatureCardProps) {
  return (

      <CardBody className="grid place-items-center px-0  transform hover:scale-105 transition duration-300  " color="transparent"  placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
        <div className="mb-3 grid place-content-center rounded-lg text-blue-gray-900">
          <Icon className="h-10 w-10 place-content-center" color= "teal" />
        </div>
        <Typography variant="h5" color="blue-gray" className="mb-2"  placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          {title}
        </Typography>
        <Typography className="text-black-800 font-normal" variant="h6"  placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          {children}
        </Typography>
      </CardBody>
    
  );
}


export default FeatureCard;
