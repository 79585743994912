import { Typography, Button, Input } from "@material-tailwind/react";

const LINKS = [
  {
    title: "Company",
    items: ["About Us", "Our Courses", "Contact Us", "Gallery"],
  },
 
 
];

const CURRENT_YEAR = new Date().getFullYear();

export function Footer() {
  return (
    <footer className="px-8 pt-24 pb-8">
      <div className="container max-w-6xl flex flex-col mx-auto">
        <div className="grid grid-cols-1 lg:grid-cols-4 !w-full ">
        <div>
        <Typography variant="h6" color="blue-gray" className="mb-4 font-bold"  placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                  {"Hope International Aged Care Training and Elderly Care Center"}
                </Typography>

     
        <Typography variant="h6" color="blue-gray" className="mb-4 font-normal"  placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                  {"At Hope International, we are driven by a passion for enhancing the quality of life for elderly individuals and empowering caregivers to make a meaningful difference in their lives. Join us in our journey towards creating a future where every senior receives the respect, dignity, and care they deserve.Trust us with your passion, and let us work together to achieve the best possible outcomes for you and your goal."}
                </Typography>
                </div>
             
        
          <div className="items-center gap-10 ml-20 mb-10 lg:mb-0 md:gap-16">
            {LINKS.map(({ title, items }) => (
              <ul key={title}>
                <Typography variant="h6" color="blue-gray" className="mb-2"  placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                  {title}
                </Typography>
                {items.map((link) => (
                  <li key={link}>
                    <Typography
                      as="a"
                      href="#"
                      className="py-1 font-normal !text-gray-700 transition-colors hover:!text-gray-900"  placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}                    >
                      {link}
                    </Typography>
                  </li>
                ))}
              </ul>
            ))}
          </div>
          
          {/* <div className="">
            <Typography variant="h6" className="mb-3 text-left">
              Subscribe
            </Typography>
            <Typography className="!text-gray-500 font-normal mb-4 text-base">
              Get access to subscriber exclusive deals and be the first who gets
              informed about fresh sales.
            </Typography>
            <Typography variant="small" className="font-medium mb-2 text-left">
              Your Email
            </Typography>
            <div className="flex mb-3 flex-col lg:flex-row items-start gap-4">
              <div className="w-full">
                {/* @ts-ignore */}
                {/* <Input label="Email" color="gray" />
                <Typography className="font-medium mt-3 !text-sm !text-gray-500 text-left">
                  I agree the{" "}
                  <a
                    href="#"
                    className="font-bold underline hover:text-gray-900 transition-colors"
                  >
                    Terms and Conditions{" "}
                  </a>
                </Typography>
              </div>
              <Button color="gray" className="w-full lg:w-fit" size="md">
                button
              </Button>
            </div>
          </div> */} 
          <div>

          <Typography variant="h6" color="blue-gray" className="mb-4 font-bold" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                  {"Our Location"}
                </Typography>

  <iframe 
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d56522.59211451086!2d85.26497023124999!3d27.696839499999992!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb198d32e428a9%3A0x3f168c6bdffc0c64!2sHope%20International%20Aged%20Care%20Training%20and%20Elderly%20Care%20Center!5e0!3m2!1sen!2snp!4v1715100246835!5m2!1sen!2snp" 
        width="360" 
        height="350" 
    
        style={{ border: 0 }} 
        loading="lazy" 
        referrerPolicy="no-referrer-when-downgrade"
        title="Hope International Aged Care Training and Elderly Care Center Location"
      >
      </iframe>
      </div>

        </div>
        <Typography
          color="blue-gray"
          className="md:text-center mt-16 font-normal !text-gray-700" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}        >
          &copy; {CURRENT_YEAR} Hope International Aged Care Training And Elderly Care Center.
        </Typography>
      </div>
    </footer>
  );
}

export default Footer;
