import { Typography, Card, CardBody, CardHeader, Button } from "@material-tailwind/react";
import Image from "next/image";
import Modal from './show-modal-with-text';
import { useState } from "react";

interface CourseCardProps {
  heading: string;
  title: string;
  desc: string;
  
}



export function CourseCard({ heading, title, desc, }: CourseCardProps) {

  const [modalOpen, setModalOpen] = useState(false);

const openModal =  () =>{
   setModalOpen(true);
};

const closeModal = () => {
  setModalOpen(false);
};



  return (
    <Card 
    color="transparent" 
    className="shadow-lg transform hover:scale-105 transition duration-300 px-5 py-5" 
    shadow={false} 
    placeholder={undefined} 
    onPointerEnterCapture={undefined} 
    onPointerLeaveCapture={undefined}
  >
      <CardHeader floated={false} className="mx-0 mt-0 mb-6 h-48" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
      <div className="relative w-full h-full">
  <Image width={768} height={768} src={heading} alt={title} className="h-full w-full object-cover" />
 
</div>
      </CardHeader>
      <CardBody className="p-0"  placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
        <a
          href="#"
          className="text-blue-gray-900 transition-colors"
        >
          <Typography variant="h5" className="mb-2"  placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            {title}
          </Typography>
        </a>
        <Typography className="mb-6 font-normal !text-black-500"  placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          {desc}
        </Typography>
        {/* <Button color="gray" size="sm" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}
        // onClick={}
        >
       
        
          {buttonLabel}
          
        </Button> */}
        <Modal isOpen={modalOpen} onClose={closeModal} text={desc}/>
      </CardBody>
    </Card>
  );
}



export default CourseCard;


